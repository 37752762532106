.details {
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

  .content {
    &[data-state="open"] {
      animation: slideDown 300ms ease-out;
    }

    &[data-state="closed"] {
      animation: slideUp 300ms ease-in;
    }
  }
}

.trigger,
.content a {
  transition: all 0.3s ease;
  color: #fff;
  &:hover {
    color: var(--gold-color);
  }
}
