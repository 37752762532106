.carousel {
  --text-color: white;
  .slide {
    flex: 0 0 1100px;
    max-width: 100%;
    /* img {
      filter: grayscale(0);
      transition: all 0.25s ease;
    }
    &.active {
      img {
        filter: none;
      }
    } */
  }
}
